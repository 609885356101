var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caricature"},[_vm._m(0),_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"swiperBox"},[(_vm.bannerList.length)?_c('div',{staticClass:"carousel"},[_vm._l((_vm.computedItems),function(item,index){return _c('div',{key:index,staticClass:"carousel-item",style:({
                transform: ("scale(" + (item.scale) + ") translate(" + (item.translateX) + "px, " + (item.translateY) + "px)"),
                zIndex: item.zIndex,
                width: ((item.width) + "px"),
                height: ((item.height) + "px"),
              }),on:{"touchstart":_vm.handleTouchstart,"touchmove":_vm.handleTouchmove,"touchend":_vm.handleTouchend,"click":function($event){return _vm.jump(_vm.bannerList[index], index)}}},[_c('ImgDecypt',{key:item.id,style:({ width: '100%', height: '100%' }),attrs:{"src":_vm.bannerList[index].verticalCover,"alt":'ImageError ' + (index + 1)}})],1)}),_c('div',{staticClass:"carousel-indicators"},_vm._l((_vm.items),function(item,index){return _c('span',{key:index,class:{ active: index === _vm.currentIndex }})}),0)],2):_vm._e(),_c('div',{staticClass:"bgIMage",style:(_vm.backgroundStyle)})])]),_c('van-sticky',{attrs:{"offset-top":"7.466rem"},on:{"change":_vm.changeRefreshDisable}}),_c('van-sticky',{attrs:{"offset-top":"2.107rem"},on:{"change":_vm.suctionCap}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"barTopic"},_vm._l((_vm.jingangArea),function(topicItem,index){return _c('div',{key:topicItem.id,staticClass:"barTopicItem",class:index === 0
                ? 'jgItem1'
                : index === 1
                ? 'jgItem2'
                : index === 2
                ? 'jgItem3'
                : '',on:{"click":function($event){return _vm.moreMore(topicItem)}}},[_c('p',[_c('span',[_vm._v(_vm._s(topicItem.name))])])])}),0),_c('div',{ref:"swipeContent"},[_c('div',{staticClass:"videoContent"},[(!_vm.isLoading)?_c('PullRefresh',{attrs:{"disabled":_vm.isRefreshDisable,"loading":_vm.loading,"refreshing":_vm.refreshing,"finished":_vm.finished,"isNoData":_vm.isNoData,"error":_vm.error,"is-higeht-max":true,"hasAdvPagination":true,"className":"Home"},on:{"onLoad":_vm.onLoad,"onRefresh":_vm.onRefresh}},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index},[(item['direction'] == 0 && item['MediaList'])?_c('VerticleThree',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae,"num":3}}):_vm._e(),(item['direction'] == 1 && item['MediaList'])?_c('VerticleThree',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae,"num":6}}):_vm._e(),(item['direction'] == 2 && item['MediaList'])?_c('VerticleTwo',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae}}):_vm._e(),(item['direction'] == 3 && item['MediaList'])?_c('TransverselyTwo',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae}}):_vm._e(),(item['direction'] == 4 && item['MediaList'])?_c('TransverselyFive',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae}}):_vm._e(),(item['direction'] == 5 && item['MediaList'])?_c('TransverselyTwo',{attrs:{"videoInfoData":item,"tabNmae":_vm.tabNmae,"num":6}}):_vm._e()],1)}),0):_c('Loading')],1)])]),_c('div',{staticStyle:{"width":"100%","height":"300px"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header flex-center-center"},[_c('img',{attrs:{"src":require("@/assets/png/originalTitlt.png"),"alt":""}})])}]

export { render, staticRenderFns }