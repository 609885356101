<template>
  <div class="verticleThreeList">
    <div class="videoInfo">
      <div
        class="videoInfoItem"
        v-for="(item, index) in videoList"
        :key="item + index"
      >
        <div class="advBox" v-if="item.adv" @click="goAdv(item.adv)">
          <ImgDecypt
            class="advImg"
            :key="item.adv.cover"
            :src="item.adv.cover"
          />
          <div class="advTitle">{{ item.adv.title }}</div>
        </div>
        <div class="videoItem" v-else>
          <!-- 编辑模式 -->
          <div class="videoEdit" v-if="isEdit" @click="del(item)">
            <img src="@/assets/png/videoDel.png" />
          </div>

          <div class="videoLabel beanLabel" v-if="item.permission == 1">
            <svg-icon class="Golden" iconClass="Golden"></svg-icon>
            {{
              (item.kind == 4 || item.kind == 1) && item.price > 0
                ? item.price
                : ""
            }}
            <!-- <span>金豆</span> -->
          </div>

          <div class="videoLabel freeLabel" v-if="item.permission == 2">
            <span>免费</span>
          </div>

          <div class="previewImg" @click="jump(item, index)">
            <ImgDecypt
              :key="item['verticalCover']"
              :src="item['verticalCover']"
            />
            <div class="mask">
              <div
                class="maskText"
                :class="{ updateEnd: item.updateStatus == 2 }"
              >
                {{ item.updatedDesc }}
              </div>
            </div>
          </div>

          <div class="videoTxt">
            <p class="videoTxtTitle ellipsis">{{ item.title }}</p>
            <div v-if="!item.adv && item['tagNames']">
              <span
                v-for="(itemTag, indexTag) in item['tagNames'].slice(0, 3)"
                :key="indexTag"
                class="videoTxtTag"
                @click="tagJump(item, indexTag, itemTag)"
              >
                <span>
                  {{ itemTag }}
                </span>
                <br v-if="(indexTag + 1) % 3 == 0" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 竖版视频列表（三）
 */
import ImgDecypt from "@/components/ImgDecypt";
import { jumpAdv } from "@/utils/getConfig";
export default {
  name: "VerticleThreeList",
  components: {
    ImgDecypt,
  },
  props: {
    videoList: {
      //视频列表
      type: Array,
    },
    isEdit: {
      //是否编辑模式
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {},
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    jump(item, index) {
      console.log(this.$route);
      let kind = item["kind"];
      if (kind != null) {
        switch (kind) {
          case 0:
          case 2:
          case 3:
          case 6:
            if (this.$route.path == "/videoDetailsPage") {
              //跳转视频
              this.$router.replace({
                path: "/videoDetailsPage",
                query: {
                  id: item.id,
                  kind: kind,
                  fromTagList: this.$route.path == "/tagList" ? true : false,
                },
              });
            } else {
              //跳转视频
              this.$router.push({
                  path: "/videoDetailsPage",
                  query: {
                      id: item.id,
                      kind: kind,
                      fromTagList: this.$route.path == '/tagList' ? true : false
                  }
              })
            }

            break;
          case 4:
          case 1:
            //小说/漫画
            this.$router.push({
              path: "/novelDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                fromTagList: this.$route.path == "/tagList" ? true : false,
                price: item.price,
              },
            });
            break;
          case 5:
            var list = JSON.stringify(this.videoList);
            //图集
            this.$router.push({
              path: "/portrayPage",
              query: {
                id: item.id,
                kind: item.kind,
                list: this.encodeBase64(list),
                phoneIndex: index,
                fromTagList: this.$route.path == "/tagList" ? true : false,
              },
            });
            break;
        }
      }
    },
    del(item) {
      this.$emit("del", item);
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.verticleThreeList {
  height: 100%;
  width: 100%;

  .videoInfo {
    display: grid;
    grid-template-columns: 111px 111px 111px;
    // margin-top: 13px;
    grid-gap: 9px;

    .videoInfoItem {
      //width: 31.5%;
      width: 100%;
      margin-bottom: 12px;
      .advBox {
        color: #000000;
        .advImg {
          width: 100%;
          height: 162px;
          // border-radius: 6px;
          /deep/ img {
            object-fit: fill !important;
            // border-radius: 6px;
          }
        }
        .advTitle {
          margin-top: 6px;
          font-size: 12px;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .videoEdit {
        width: 100%;
        // width: 31.5%;
        height: 162px;
        // border-radius: 6px;
        background: rgba(0, 0, 0, 0.72);
        position: absolute;
        z-index: 10;

        img {
          width: 23px;
          height: 24px;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .videoItem {
        position: relative;
      }

      .videoLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        // width: 36px;
        padding: 1px 4px;
        // border-radius: 3px;
        z-index: 1;
        top: 0px;
        left: 10px;
        // margin-top: 6px;
        // margin-left: 70px;
        text-wrap: none;
      }

      .vipLabel {
        color: rgb(102, 61, 0);
        background-image: linear-gradient(
          to right,
          rgb(255, 231, 168),
          rgb(242, 202, 91)
        );
      }

      .beanLabel {
        font-size: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: rgb(255, 255, 255);
        background: rgba(255, 143, 76, 1);
       .Golden {
          width: 14px;
          height: 14px;
        }
        // background-image: linear-gradient(
        //   to right,
        //   rgb(255, 157, 87),
        //   rgb(255, 89, 34)
        // );
      }

      .freeLabel {
        width: 30px;
        height: 16px;
        font-size: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: rgb(255, 255, 255);
        background-image: linear-gradient(
          to right,
          rgb(40, 249, 218),
          rgb(86, 143, 254)
        );
      }

      .previewImg {
        width: 100%;
        height: 162px;
        border-radius: 8px !important;
        background: rgba(27, 22, 76, 0.06);
        position: relative;

        // /deep/ .van-image {
        //     border-radius: 6px;
        // }

        /deep/ .van-image__img {
            border-radius: 8px;
        }
        .mask {
          position: absolute;
          bottom: 0;
          z-index: 1;
          width: 100%;
          height: 81px;
          border-radius: 8px;
          //background-image: linear-gradient(
          //  to bottom,
          //  rgba(0, 0, 0, 0),
          //  rgba(0, 0, 0, 1)
          //);
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          font-size: 8px;
          .maskText {
            margin: 0 6px 6px 0;
            color: rgba(255, 241, 128, 1);
          }
          .updateEnd {
            color: #fff;
          }
          // background-image: linear-gradient(to right, rgb(255,231,168) , rgb(242,202,91));
        }
      }

      .videoTxt {
        margin-top: 6px;

        .videoTxtTitle {
          font-size: 12px;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .videoTxtTag {
          font-size: 10px;
          font-weight: 400;
          color: #4A4A4A;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
