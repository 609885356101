<template>
    <div class="transverselyTwo">
        <div class="videoItem">
            <div class="titleBar">
                <div>
                    <p class="titleName">{{videoInfoData.name}}</p>
                    <p class="titleDetails">{{videoInfoData.desc}}</p>
                </div>
                <div class="moreRight" @click="moreMore">
                    <!-- <span class="moreRightTitle">更多</span> -->
                    <img src="@/assets/png/moreRight.png" />
                </div>
            </div>
            <div class="videoInfo">
                <TransverselyTwoList :videoList="num?videoInfoData['MediaList'].slice(0,num):videoInfoData['MediaList'].slice(0,4)"></TransverselyTwoList>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 横版视频列表（二）
 * ps:
 *      direction = 3   (两行)
 */
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
export default {
    name: "TransverselyTwo",
    components:{
        TransverselyTwoList
    },
    props:{
        videoInfoData:{
            type: Object
        },
        num:{
            type:Number
        },
        tabNmae: String,
    },methods:{
        moreMore(){
            this.$router.push({
                path:"topicPage",
                query:{
                    topicId:this.videoInfoData.id,
                    zone:this.videoInfoData.zone,
                    name:this.videoInfoData.name,
                    format: 1,
                    tabNmae:this.tabNmae
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.transverselyTwo{

    .videoItem{
        border-bottom: solid 1px rgb(230,230,230);
        padding-bottom: 12px;
        margin-top: 12px;

        .titleBar{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .titleName{
                font-size: 18px;
                color: #000000;
                font-weight: bold;
            }

            .titleDetails{
                font-size: 12px;
                color: #999999;
            }

            .moreRight{
                color: #000000;

                .moreRightTitle{
                    font-size: 14px;
                    margin-right: 6.1px;
                }

                img{
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .videoInfo{
            margin-top: 9px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
</style>
