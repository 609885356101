import axios from "@/utils/request";

/************************* 妖精原创 *************************/

// 话题详情
//   GET /api/app/modules/section?sectionID=X&simpleSort=0&pageNumber=1&pageSize=10 
//     sectionID  上一个接口返回的话题id

// 轮播视频(点击播放跳视频详情页)
export function originMedias() {
  return axios.get(`modules/original/medias`);
}

// 模块列表
export function moduleList(data) {
  return axios.get(`modules/original/sections`, { params: data });
}

// 模块详情
// 参数sectionID=X  simpleSort=0  pageNumber=1 pageSize=10
export function moduleListDetails(data) {
  return axios.get(`modules/original/sectionList`, { params: data });
}

