<template>
  <div class="caricature">
    <div class="header flex-center-center">
      <img src="@/assets/png/originalTitlt.png" alt="" />
    </div>
    <div class="mainBox">
      <div class="head">
        <div class="swiperBox">
          <div class="carousel" v-if="bannerList.length">
            <div
              class="carousel-item"
              @touchstart="handleTouchstart"
              @touchmove="handleTouchmove"
              @touchend="handleTouchend"
              v-for="(item, index) in computedItems"
              @click="jump(bannerList[index], index)"
              :key="index"
              :style="{
                transform: `scale(${item.scale}) translate(${item.translateX}px, ${item.translateY}px)`,
                zIndex: item.zIndex,
                width: `${item.width}px`,
                height: `${item.height}px`,
              }"
            >
              <ImgDecypt
                :src="bannerList[index].verticalCover"
                :key="item.id"
                :style="{ width: '100%', height: '100%' }"
                :alt="'ImageError ' + (index + 1)"
              />
            </div>
            <div class="carousel-indicators">
              <span
                v-for="(item, index) in items"
                :key="index"
                :class="{ active: index === currentIndex }"
              ></span>
            </div>
          </div>
          <div class="bgIMage" :style="backgroundStyle"></div>
          <!-- <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in bannerList"
              :key="index"
              @click="goAdv(item)"
            >
              <ImgDecypt :src="item.cover" :key="item.cover" />
            </van-swipe-item>
          </van-swipe> -->
        </div>
      </div>
      <!-- <van-sticky offset-top="9.59rem" @change="changeRefreshDisable"></van-sticky>
            <van-sticky offset-top="2.107rem" @change="suctionCap"></van-sticky> -->
      <van-sticky
        offset-top="7.466rem"
        @change="changeRefreshDisable"
      ></van-sticky>
      <van-sticky offset-top="2.107rem" @change="suctionCap"></van-sticky>
      <!-- 金刚区 -->
      <div class="content">
        <div class="barTopic">
          <div
            class="barTopicItem"
            :class="
              index === 0
                ? 'jgItem1'
                : index === 1
                ? 'jgItem2'
                : index === 2
                ? 'jgItem3'
                : ''
            "
            v-for="(topicItem, index) in jingangArea"
            :key="topicItem.id"
            @click="moreMore(topicItem)"
          >
            <!-- <ImgDecypt
              class="topicImg"
              :src="topicItem.img"
              :key="topicItem.img"
            /> -->
            <p>
              <span>{{ topicItem.name }}</span>
            </p>
          </div>
        </div>

        <div ref="swipeContent">
          <div class="videoContent">
            <PullRefresh
              :disabled="isRefreshDisable"
              v-if="!isLoading"
              :loading="loading"
              :refreshing="refreshing"
              :finished="finished"
              @onLoad="onLoad"
              @onRefresh="onRefresh"
              :isNoData="isNoData"
              :error="error"
              :is-higeht-max="true"
              :hasAdvPagination="true"
              className="Home"
            >
              <div v-for="(item, index) in videoList" :key="index">
                  <VerticleThree
                    v-if="item['direction'] == 0 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                    :num="3"
                  ></VerticleThree>
                  <VerticleThree
                    v-if="item['direction'] == 1 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                    :num="6"
                  ></VerticleThree>
                  <VerticleTwo
                    v-if="item['direction'] == 2 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                  ></VerticleTwo>
                  <TransverselyTwo
                    v-if="item['direction'] == 3 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                  ></TransverselyTwo>
                  <TransverselyFive
                    v-if="item['direction'] == 4 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                  ></TransverselyFive>
                  <TransverselyTwo
                    v-if="item['direction'] == 5 && item['MediaList']"
                    :videoInfoData="item"
                    :tabNmae="tabNmae"
                    :num="6"
                  >
                  </TransverselyTwo>
<!--                <VerticleThree :videoInfoData="item"></VerticleThree>-->
<!--                <VerticleTwo-->
<!--                  v-if="item['direction'] == 2"-->
<!--                  :videoInfoData="item"-->
<!--                ></VerticleTwo>-->
<!--                <TransverselyTwo-->
<!--                  v-if="item['direction'] == 3"-->
<!--                  :videoInfoData="item"-->
<!--                ></TransverselyTwo>-->
<!--                <TransverselyFive-->
<!--                  v-if="item['direction'] == 4"-->
<!--                  :videoInfoData="item"-->
<!--                ></TransverselyFive>-->
<!--                <TransverselyTwo-->
<!--                  v-if="item['direction'] == 5"-->
<!--                  :videoInfoData="item"-->
<!--                  :num="6"-->
<!--                >-->
<!--                </TransverselyTwo>-->
                <!-- <div
                  class="advertising"
                  v-if="advList.length > 0"
                  @click="goAdv(advList[index % advList.length])"
                >
                  <ImgDecypt
                    class="advImg"
                    :src="advList[index % advList.length].cover"
                  />
                </div> -->
              </div>
            </PullRefresh>
            <Loading v-else />
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 300px"></div>
    </div>

    <!--    &lt;!&ndash; 9 宫格广告 &ndash;&gt;-->
    <!--    <AdvSudoku-->
    <!--      :advList="advSudokuList"-->
    <!--      :show="advSudokuShow"-->
    <!--      @close="advSudokuClose"-->
    <!--    />-->

    <!--    &lt;!&ndash; 系统公告 &ndash;&gt;-->
    <!--    <AnnouncementText-->
    <!--      :advList="announcementTextList"-->
    <!--      :show="announcementTextShow"-->
    <!--      @close="announcementTextClose"-->
    <!--    />-->

    <!--    &lt;!&ndash; 图片广告 &ndash;&gt;-->
    <!--    <AdvPicture-->
    <!--      :advList="advPictureList"-->
    <!--      :show="advPictureShow"-->
    <!--      @close="advPictureClose"-->
    <!--    />-->

    <!-- <FloatingAdv /> -->
  </div>
</template>

<script>
import AdvSudoku from "@/components/AdvSudoku";
import AdvPicture from "@/components/AdvPicture";
import { queryTopicList } from "@/api/video";
import { originMedias, moduleList } from "@/api/original";
import Loading from "@/components/Loading";
import VerticleThree from "@/components/OriginalVideoList/VerticleThree";
import VerticleTwo from "@/components/OriginalVideoList/VerticleTwo";
import TransverselyTwo from "@/components/OriginalVideoList/TransverselyTwo";
import TransverselyFive from "@/components/OriginalVideoList/TransverselyFive";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import FloatingAdv from "@/components/FloatingAdv";
import { imgDecyptApi } from "@/api/app";
import { mapGetters } from "vuex";

import { getSessionItem, setSessionItem } from "@/utils/longStorage";

import {
  AdType,
  getAdItem,
  jumpAdv,
  getMarquee,
  MarqueeType,
  getOfficialConfigApps,
  getAnnouncementText,
} from "@/utils/getConfig";
import AnnouncementText from "@/components/AnnouncementText";
export default {
  name: "original",
  components: {
    PullRefresh,
    Loading,
    VerticleThree,
    VerticleTwo,
    TransverselyTwo,
    TransverselyFive,
    ImgDecypt,
    // AnnouncementText,
    // AdvSudoku,
    // AdvPicture,
    // FloatingAdv,
  },
  data() {
    return {
      tabNmae: "涩漫剧场",
      backgroundImage: "",
      decyImg: [],
      startX: 0, // 触摸开始时的X坐标
      currentX: 0, // 当前轮播图的X坐标
      items: [
        {
          width: 197,
          height: 262,
          scale: 1,
          translateX: 0,
          zIndex: 4,
        },
        {
          width: 84,
          height: 207,
          scale: 0.5,
          translateX: 50,
          zIndex: 3,
        },
        {
          width: 84,
          height: 130,
          scale: 0.4,
          translateX: 100,
          zIndex: 2,
        },
        {
          width: 74,
          height: 106,
          scale: 0.3,
          translateX: 150,
          zIndex: 1,
        },
      ],
      videoList: [],
      currentIndex: 0,
      swipeHeight: 0,
      isLoading: true,
      loading: true,
      isNoData: false,
      finished: false,
      suctionCapState: false,
      refreshing: false,
      marqueeText: null,
      error: false,
      bannerList: [],
      advSudokuShow: false, //九宫格广告弹窗
      advSudokuList: [], //九宫格广告数据
      announcementTextShow: false, //公告弹窗
      announcementTextList: [], //公告数据
      advPictureShow: false, //图片广告弹窗
      advPictureList: [], //图片广告数据
      listAdv: [], //首页列表广告
      advList: [], //专栏广告
      isRefreshDisable: false,
      jingangArea: [],
    };
  },
  activated() {},
  computed: {
    ...mapGetters({
      imgApi: "imgApi",
    }),
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
    // 轮播图
    computedItems() {
      let tempIndex = this.currentIndex;
      let prevIndex = (tempIndex - 1 + this.items.length) % this.items.length;

      return this.items.map((item, index) => {
        let offset = index - tempIndex;
        if (offset < 0) offset += this.items.length;

        let firstTranslateY = index === tempIndex ? -15 : 0;
        let secondTranslateY =
          index === (tempIndex + 1) % this.items.length ? -5 : 0;
        let extraTranslateX = index === prevIndex ? 25 : 0;
        let endY = firstTranslateY + secondTranslateY;

        return {
          ...item,
          scale: 1 - 0.1 * offset,
          translateX: 110 * offset + 10 * (offset - 1) + extraTranslateX,
          translateY: endY,
          zIndex: this.items.length - offset,
          width: 197 - 31 * offset,
          height: 262 - 52 * offset,
        };
      });
    },
  },
  created() {
    this.queryTopicListInfo();
    // this.listAdv = getAdItem(AdType.COLUMN_ADV);
    // let appConfig = JSON.parse(getSessionItem("appConfig"));
    // this.jingangArea = appConfig.jingangArea.list || [];
  },
  mounted() {
    this.imgDecypt();
    // 轮播图
    this.getBannerList();
    // this.updateItems();
    setInterval(this.next, 4000);

    this.advSudokuList = getOfficialConfigApps();
    this.announcementTextList = getAnnouncementText();
    this.advPictureList = getAdItem(AdType.FREE_VIDEO).reverse();
    this.advList = getAdItem(AdType.COLUMN_ADV);
    this.marqueeText = getMarquee(MarqueeType.community);

    if (
      this.advSudokuList.length > 0 &&
      getSessionItem("advSudokuPop") != "1"
    ) {
      setSessionItem("advSudokuPop", "1");
      this.advSudokuShow = true;
    } else if (
      this.announcementTextList.length > 0 &&
      getSessionItem("announcementTextPop") != "1"
    ) {
      setSessionItem("announcementTextPop", "1");
      this.announcementTextShow = true;
    } else if (
      this.advPictureList.length > 0 &&
      getSessionItem("advPicturePop") != "1"
    ) {
      setSessionItem("advPicturePop", "1");
      this.advPictureShow = true;
    }
  },
  methods: {
    imgDecypt(src) {
      try {
        let imgApi = this.imgApi;
        if (imgApi.slice(-1) != "/") {
          imgApi = imgApi + "/";
        }
        if (!imgApi || !src) return;
        imgDecyptApi(imgApi + src).then((url) => {
          this.decyImg.push(url);
        });
      } catch (error) {
        console.log(error);
      }
    },
    moreMore(item) {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: item.id,
          zone: item.zone,
          name: item.name,
          format: 0,
          tabNmae: this.tabNmae,
        },
      });
    },
    // 轮播图视频跳转
    jump(item, index) {
      let kind = item["kind"];
      if (kind != null) {
        this.$router.push({
          path: "/videoDetailsPage",
          query: {
            id: item.id,
            kind: kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    // 轮播图数据
    async getBannerList(type) {
      let res = await this.$Api(originMedias);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      try {
        if (res && res.code == 200) {
          // console.log(res);
          const { data } = res;

          data.list.forEach((item) => {
            let cover = item.verticalCover;
            this.imgDecypt(cover);
          });
          if (type == "refresh") {
            this.bannerList = data.list;
          } else {
            this.bannerList = this.bannerList.concat(data.list);
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    handleTouchstart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchmove(event) {
      const dx = event.touches[0].clientX - this.startX; // 计算滑动距离
      this.currentX += dx; // 更新轮播图位置
      this.startX = event.touches[0].clientX; // 更新触摸位置
    },
    handleTouchend() {
      if (this.currentX > 0) {
        this.next();
      } else if (this.currentX < 0) {
        this.previous();
      }
      this.currentX = 0; // 重置currentX
    },
    // 轮播图下一张
    next() {
      this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      // this.updateItems();
    },
    // 轮播图上一张
    previous() {
      this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.items.length;
    },
    // updateItems() {
    //   let tempIndex = this.currentIndex;
    //   this.items.forEach((item, index) => {
    //     let offset = index - tempIndex;
    //     if (offset < 0) offset += this.items.length;
    //     item.scale = 1 - 0.1 * offset;
    //     // let extraTranslateX = (index === this.currentIndex) ? 100 : 0;
    //     let firstTranslateY = (index === tempIndex) ? -15 : 0;
    //     let secondTranslateY = (index === (tempIndex + 1) % this.items.length) ? -5 : 0;
    //     let extraTranslateX = (index === (tempIndex - 1 + this.items.length) % this.items.length) ? 25 : 0;
    //     let endY = firstTranslateY + secondTranslateY;
    //     item.translateY = endY;

    //     item.translateX = 110 * offset + 10 * (offset - 1) + extraTranslateX; // 调整每张图片的水平位移
    //     item.zIndex = this.items.length - offset;
    //     item.width = 197 - 31 * offset;
    //     item.height = 262 - 52 * offset;
    //   });
    // },
    advPictureClose() {
      /**
       * 图片广告
       */
      // this.advPictureList.pop();
      // if (this.advPictureList.length <= 0) {
      this.advPictureShow = false;
      // }
    },
    announcementTextClose() {
      /**
       * 公告弹窗关闭
       */
      this.announcementTextShow = false;

      if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    advSudokuClose() {
      /**
       * 九宫格弹窗关闭
       */
      this.advSudokuShow = false;

      if (
        this.announcementTextList.length > 0 &&
        getSessionItem("announcementTextPop") != "1"
      ) {
        setSessionItem("announcementTextPop", "1");
        this.announcementTextShow = true;
      } else if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    clickTag(index) {
      // this.suctionCapState = false;
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.queryTopicListInfo("refresh");
      this.swipeTo(index);
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.queryTopicListInfo("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.queryTopicListInfo();
    },
    async queryTopicListInfo(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        // topicMediaSize: 6,
        // zone: this.keyName,
      };
      try {
        let res = await this.$Api(moduleList, req);
        console.log(res);
        const { data } = res;
        let listArr = res.data.normalSection || [];
        listArr = listArr.map(item => {
         if (item.media) {
          item.MediaList = item.media;  // 将 media 数组复制到 MediaList
          delete item.media;  // 删除原来的 media 属性
         }
         return item;
        });
        // console.log(data);
        // console.log(res.normalSection);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        if (res && res.code == 200) {
          if (type == "refresh") {
            this.videoList = [];
            this.videoList = this.videoList.concat(listArr);
            this.jingangArea = data.section;
          } else {
            this.videoList = this.videoList.concat(listArr);
            this.jingangArea = this.jingangArea.concat(data.section);
          }

          if (this.pageNumber === 1 && this.videoList.length === 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.list.length < req.pageSize) {
            this.finished = true;
          }
          // console.log(this.videoList, "-=-=");
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    suctionCap() {
      this.suctionCapState = !this.suctionCapState;
    },
    // 切换下拉禁用状态
    changeRefreshDisable(isFixed) {
      this.isRefreshDisable = !this.isRefreshDisable;
      // console.log(this.isRefreshDisable)
    },
    pageSlideSwipe(index) {
      /**
       * 页面滑动切换
       */
      let videoList = this.videoList;
      if (videoList.length == 0) {
        this.pageNumber = 1;
        this.refreshing = true;
        this.loading = true;
        this.isLoading = true;
        this.finished = false;
        this.isNoData = false;
        this.queryTopicListInfo("refresh");
      }
      this.swipeTo(index);
    },
    swipeTo(index) {
      this.$refs.slideSwipe.swipeTo(index);
      // this.$nextTick(()=>{
      //     this.swipeHeight = this.$refs.swipeContent[index].offsetHeight + 500;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .titleName {
//   color: #ffffff !important;
// }
// /deep/ .videoTxtTitle {
//   color: #ffffff !important;
// }
/deep/ .videoTxtTag {
  color: #999999 !important;
}

.caricature {
  height: 100%;
  // background: #191919;
  // overflow-y: auto;
  .header {
    width: 100%;
    height: 44px;
    line-height: 20px;
    // background: #000;
    background: #ffffff;
    // font-size: 16px;
    // margin-bottom: 20px;
    img {
      width: 71px;
      height: 20px;
    }
  }
  .mainBox {
    height: 100%;
    overflow-y: auto;
  }

  .loading {
    height: 100%;
  }

  .head::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 210px;
    background-image: url("~@/assets/png/originalBg.png"),
      linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1b1b1b 80%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .head {
    width: 100%;
    height: 290px;
    position: relative;
    // height: 210px;
    // background-image: url("~@/assets/png/originalBg.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    .suctionCap {
      // position: absolute;
      // width: 100%;
      // height: 88px;
      // background-image: url("~@/assets/png/headBg.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-color: rgb(255, 255, 255);
      // position: fixed;
      // top: 0;
      // z-index: 20;
    }

    // .navContentSuctionCap {
    //   // position: absolute;
    //   // top: 0;
    //   width: calc(100% - 44px);
    //   .navTabs {
    //     .navTabTitle {
    //       font-size: 14px;
    //       color:#999999;
    //       font-weight: 100;
    //     }

    //     .navTabTitleActive {
    //       color: #000 !important;
    //       font-weight: 900;
    //     }

    //     /deep/ .van-tabs__nav--complete {
    //       padding-left: 12px;
    //     }

    //     /deep/ .van-tabs__wrap--scrollable .van-tab {
    //       padding: 0;
    //       padding-right: 21px;
    //     }

    //     /deep/ .van-tabs__wrap {
    //       height: 44px;
    //       .van-tabs__line {
    //         background: transparent;
    //         background-image: url("~@/assets/png/homeTags.png") !important;
    //         background-size: 100% 100%;
    //         background-repeat: no-repeat;
    //         width: 22px !important;
    //         height: 3px !important;
    //         bottom: 20px;
    //       }
    //     }

    //     // /deep/ .van-tabs__nav--line {
    //     //   padding-bottom: 0;
    //     // }
    //   }

    //   .navRight {
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 44px;
    //     width: 44px;
    //     //background: rgb(43,39,72);

    //     img {
    //       width: 19px;
    //       height: 16px;
    //     }
    //   }
    // }

    .navContentBox {
      // position: fixed;
      // top: 0;
      width: 100%;
      height: 42px;
      z-index: 20;
      background: #ffffff;
    }

    .navContent {
      position: absolute;
      z-index: 20;
      height: 42px;
      background: #ffffff;
      .navTabs {
        padding: 0 16px;
        .navTabTitle {
          font-size: 14px;
          color: #333333;
          font-weight: 400;
        }

        .navTabTitleActive {
          font-weight: 600;
          font-size: 20px;
        }

        /deep/ .van-tabs__nav--complete {
          // padding: 0 !important;
        }

        /deep/ .van-tabs__wrap--scrollable .van-tab {
          padding: 0;
          // padding-right: 21px;
          margin-right: 21px;
        }

        /deep/ .van-tabs__wrap {
          height: 44px;
          .van-tabs__line {
            background: transparent;
            background-image: url("~@/assets/png/homeTags.png") !important;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 22px !important;
            height: 3px !important;
            bottom: 20px;
          }
        }

        // /deep/ .van-tabs__nav--line {
        //   padding-bottom: 0;
        // }
      }

      .navRight {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        //background: rgb(43,39,72);

        img {
          width: 19px;
          height: 16px;
        }
      }
    }

    .searchContent {
      // position: absolute;
      // z-index: 20;
      height: 30px;
      width: 100vw;
      // margin-top: 53px;

      .searchBar {
        display: flex;

        .search {
          width: 267px;
          height: 30px;
          border-radius: 15px;
          background: rgba(0, 0, 0, 0.15);
          font-size: 12px;
          display: flex;
          align-items: center;
          margin: 0 12px;
          color: rgb(204, 204, 204);

          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
          }
        }

        span {
          margin-left: 7px;
        }
      }

      // .swipe {
      //     height: 360px;
      // }

      .iconBlock {
        margin-right: 12px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .swiperBox {
      height: 300px;
      padding: 12px 16px;
      .carousel {
        position: relative;
        width: 100%;
        height: 280px;
      }
      .bgIMage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 210px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .bgIMage::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 210px;
        background: linear-gradient(
          180deg,
          rgba(26, 26, 26, 0) 0%,
          #1b1b1b 80%
        );
        background-repeat: no-repeat;
        background-size: cover;
      }
      .carousel-item {
        position: absolute;
        // width: 100% !important;
        height: 100%;
        bottom: 0;
        left: 6px;
        border-radius: 8px;
        transition: transform 1s, width 1s, height 1s;
        /deep/ .van-image__img {
          border-radius: 8px;
        }
        /deep/ .img-decypt {
          z-index: 99999 !important;
        }
      }
      .carousel-item::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background-image: url("~@/assets/png/originalPlay.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .carousel-indicators {
        position: absolute;
        left: 0;
        bottom: -40px;
        width: 100%;
        text-align: center;
      }
      .carousel-indicators span {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 0 5px;
        background: rgba(251, 209, 61, 1);
        opacity: 0.5;
        border-radius: 50%;
      }
      .carousel-indicators span.active {
        width: 38px;
        border-radius: 20px;
        background: rgba(251, 209, 61, 1);
        opacity: 1;
      }
    }
    .swipe {
      height: 262px;
      border-radius: 8px;

      .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.45);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
      }
      /deep/ img {
        object-fit: fill !important;
      }
    }
  }

  .content {
    margin-top: 50px;
    padding: 0 12px;
    // position: relative;

    .notice {
      margin-top: 12px;
      background: #ffffff;
      color: #999999;
      height: 25px;
      border-radius: 6px;
      display: flex;
      align-items: center;

      img {
        width: 12.4px;
        height: 12.4px;
        margin-left: 8px;
      }

      /deep/ .van-notice-bar {
        padding: 0;
        font-size: 12px;
      }

      .noticeBar {
        width: 100%;
        height: 100%;
        color: #999999 !important;
        padding: 0 13px 0 4.6px;
      }
    }

    /deep/ .videoContent {
      margin-top: 6px;
      .verticleThree {
        // .moreRight {
        //   display: none;
        // }
      }

      .advertising {
        // border-bottom: solid 1px rgb(230, 230, 230);
        padding-bottom: 12px;
        margin-top: 12px;
        /deep/ .vanImage {
          border-radius: 8px;
        }
        /deep/ .van-image__img {
          border-radius: 8px;
        }

        .advertisingImg {
          width: 351px;
          height: 73.13px;
          background: rgb(43, 39, 72);
          border-radius: 6px;
        }
      }
    }

    .barTopic {
      width: 100%;
      display: flex;
      height: 100px;
      // flex-wrap: nowrap;
      // overflow-x: auto;
      justify-content: center;
      // justify-content: space-between;
      padding: 12px 0;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
      border-bottom: solid #dadada 1px;
      box-sizing: border-box;
      overscroll-behavior: contain;
      //   position: absolute;
      //   top: 37px;
      //   z-index: 10;
      .barTopicItem {
        width: 78px;
        height: 68px;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
        p {
          margin-top: 80px;
        }
      }
      .jgItem1 {
        background-image: url("~@/assets/png/jingangArea.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .jgItem2 {
        background-image: url("~@/assets/png/jingangArea2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .jgItem3 {
        background-image: url("~@/assets/png/jingangArea3.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .barTopicItem:last-child {
        // padding-right: 30px;
      }

      .topicImg {
        width: 42px;
        height: 42px;
        margin-bottom: 4px;
      }

      img {
        width: 42px;
        height: 42px;
      }
    }
  }
}

.myChannel {
  .homeHeadBar {
    //border: solid 1px red;
    color: #000000;
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: solid 1px rgb(230, 230, 230);

    .leftIcon {
      padding: 0 12px;

      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .contentBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 0 88px;

    //padding: 0 32px;

    .blockItem {
      //width: 50%;
      //padding-left: 24px;
      //display: flex;
      //justify-content: center;
      margin-top: 30px;

      img {
        height: 88px;
        height: 88px;
      }
    }
  }
}

.adImgBox {
  width: 100%;
  height: 88px;

  /deep/ img {
    object-fit: fill !important;
  }
}

.isFirst {
  margin-top: 85px !important;
}
</style>
